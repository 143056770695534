import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import ApiServices from '@/services/ApiServices';

const state = {
  list: [],
  paginate: {
    total: 0,
    page: 1,
    size: 30,
  },
};

const mutations = {
  UPDATE_LIST(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
};

const actions = {
  async list({ commit, dispatch }, query) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of(await ApiServices.listAllTransactions(query)).pipe(
        map(response => response.data),
      );
      response$.subscribe(staffs => {
        dispatch('isPageLoading', false, { root: true });
        const { docs, totalDocs, limit, page } = staffs.data;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred getting list of transactions',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async download(
    { dispatch },
    { fileName, ext, startDate, endDate, accountNumber },
  ) {
    try {
      const query = `?file=${fileName}&ext=${ext}&startDate=${startDate}&endDate=${endDate}${
        accountNumber ? '&accountNumber=' + accountNumber : ''
      }`;
      dispatch('isLoading', true, { root: true });
      const response$ = of(await ApiServices.downloadTransactions(query));
      response$
        .subscribe(response => {
          const blob = new Blob([response.data], {
              type: 'application/octet-stream',
            }),
            a = document.createElement('a');
          a.download = `${fileName}.${ext}`;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          window.URL.revokeObjectURL(a.href);
          dispatch('isLoading', false, { root: true });
        })
        .unsubscribe();
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response?.status === 404
              ? `No transactions found for the specified date`
              : 'An error occurred getting list of transactions',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isLoading', false, { root: true });
    }
  },
  async searchList({ commit, dispatch }, query) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of(await ApiServices.searchAllTransactions(query)).pipe(
        map(response => response.data),
      );
      response$.subscribe(members => {
        dispatch('isPageLoading', false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while search for transactions',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
};

const getters = {
  getAllTransactions: state => state.list,
  getPagination: state => state.paginate,
};

export const transactions = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
