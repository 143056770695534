import Vue from 'vue';
import Vuex from 'vuex';
import createdPersistedState from 'vuex-persistedstate';
import { staffs } from './modules/staffs';
import { auth } from './modules/auth';
import SecureLS from 'secure-ls';
import { members } from './modules/members';
import { transactions } from './modules/transactions';
import { reports } from './modules/reports';

Vue.use(Vuex);
const ls = new SecureLS({
  isCompression: false,
});
export default new Vuex.Store({
  plugins: [
    createdPersistedState({
      key: 'cloud.access',
      paths: ['auth.user'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: {
    showSnackBar: false,
    message: '',
    status: '',
    timeout: 4000,
    isPageLoading: false,
    isLoading: false,
    isSubLoading: false,
    isListLoading: false,
    resetFormState: false,
    dialogState: {
      add: false,
      edit: false,
      delete: false,
      details: false,
      generate: false,
      price: false,
      alert: false,
      logout: false,
    },
  },
  mutations: {
    UPDATE_SNACKBAR(state, payload) {
      state.showSnackBar = payload;
    },
    UPDATE_MESSAGE(state, payload) {
      state.message = payload;
    },
    UPDATE_STATUS(state, payload) {
      state.status = payload;
    },
    UPDATE_PAGELOADING(state, payload) {
      state.isPageLoading = payload;
    },
    UPDATE_LOADING(state, payload) {
      state.isLoading = payload;
    },
    UPDATE_SUB_DIALOG_LOADING(state, payload) {
      state.isSubLoading = payload;
    },
    UPDATE_LIST_LOADING(state, payload) {
      state.isListLoading = payload;
    },
    UPDATE_RESET_FORM_STATE(state, payload) {
      state.resetFormState = payload;
    },
    UPDATE_DIALOG_STATE(state, payload) {
      state.dialogState[payload.idx] = payload.status;
    },
  },
  actions: {
    isLoading({ commit }, payload) {
      commit('UPDATE_LOADING', payload);
    },
    isPageLoading({ commit }, payload) {
      commit('UPDATE_PAGELOADING', payload);
    },
    isSubLoading({ commit }, payload) {
      commit('UPDATE_SUB_DIALOG_LOADING', payload);
    },
    isListLoading({ commit }, payload) {
      commit('UPDATE_LIST_LOADING', payload);
    },
    resetFormState({ commit }, payload) {
      commit('UPDATE_RESET_FORM_STATE', payload);
    },
    updateDialog({ commit }, payload) {
      commit('UPDATE_DIALOG_STATE', payload);
      setTimeout(() => {
        commit('UPDATE_SNACKBAR', false);
      }, 3000);
    },
    resetSnackBarState({ commit }) {
      setTimeout(() => {
        commit('UPDATE_SNACKBAR', false);
      }, 3000);
    },
    showSnackBar({ commit }, payload) {
      const { snackBar, message, status } = payload;
      commit('UPDATE_SNACKBAR', snackBar);
      commit('UPDATE_MESSAGE', message);
      commit('UPDATE_STATUS', status);

      setTimeout(() => {
        commit('UPDATE_SNACKBAR', false);
        commit('UPDATE_MESSAGE', '');
        commit('UPDATE_STATUS', 'black');
      }, 3000);
    },
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getIsSubLoading: state => state.isSubLoading,
    getIsListLoading: state => state.isListLoading,
    getIsPageLoading: state => state.isPageLoading,
    getDialogState: state => id => state.dialogState[id],
    getSnackBarState: state => state.showSnackBar,
    getSnackBarMessage: state => state.message,
    getSnackBarStatus: state => state.status,
    getSnackBarTimeout: state => state.timeout,
    getResetFormState: state => state.resetFormState,
  },
  modules: {
    auth,
    staffs,
    members,
    transactions,
    reports,
  },
});
