/* eslint-disable no-unused-vars */
import { of } from 'rxjs';
import ApiServices from '@/services/ApiServices';
import { map } from 'rxjs/operators';
import Router from '../../router/index';
const state = {
  list: [],
  paginate: {
    total: 0,
    page: 1,
    size: 10,
  },
  details: {},
  transactionList: [],
};

const mutations = {
  UPDATE_LIST(state, payload) {
    state.list = payload;
  },
  UPDATE_TRANSACTION_LIST(state, payload) {
    state.transactionList = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
};

const actions = {
  async rawList({ commit, dispatch }, query) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of(await ApiServices.allStaffForAdmin(query)).pipe(
        map(response => response.data),
      );
      response$.subscribe(staffs => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_LIST', staffs.data);
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while getting list of staff members',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async list({ commit, dispatch }, query) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of(await ApiServices.allStaffForAdmin(query)).pipe(
        map(response => response.data),
      );
      response$.subscribe(staffs => {
        dispatch('isPageLoading', false, { root: true });
        const { docs, totalDocs, limit, page } = staffs.data;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while authenticating user',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of(await ApiServices.staffMemberDetails(id)).pipe(
        map(response => response.data),
      );
      response$.subscribe(staff => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_DETAILS', staff.data);
        console.log(staff);
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while authenticating user',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async listWithTrash({ commit, dispatch }, query) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of(
        await ApiServices.allDeletedStaffForAdmin(query),
      ).pipe(map(response => response.data));
      response$.subscribe(staffs => {
        dispatch('isPageLoading', false, { root: true });
        const { docs, totalDocs, limit, page } = staffs.data;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while getting list of staff members',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of(await ApiServices.addNewStaffFroAdmin(payload)).pipe(
        map(response => response.data),
      );
      response$.subscribe(staffs => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'showSnackBar',
          {
            snackBar: true,
            message: staffs.data?.message ?? 'Staff member successfully added',
            status: 'success',
          },
          { root: true },
        );
        setTimeout(() => {
          Router.push({ name: 'admin.staffs.all' }).catch(() => {});
        }, 2000);
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while authenticating user',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isLoading', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const { _id, ...rest } = payload;
      const response$ = of(
        await ApiServices.updateStaffForAdmin(_id, rest),
      ).pipe(map(response => response.data));
      response$.subscribe(staffs => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'showSnackBar',
          {
            snackBar: true,
            message: staffs.message ?? 'Staff member successfully updated',
            status: 'success',
          },
          { root: true },
        );
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while updating new staff details',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isLoading', false, { root: true });
    }
  },
  async delete({ commit, dispatch }, id) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of(await ApiServices.deleteStaffForAdmin(id)).pipe(
        map(response => response.data),
      );
      response$.subscribe(staffs => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', `?page=1&size=10`);
        dispatch(
          'showSnackBar',
          {
            snackBar: true,
            message:
              staffs.data?.message ?? 'Staff member successfully deleted',
            status: 'success',
          },
          { root: true },
        );
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while authenticating user',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isLoading', false, { root: true });
    }
  },
  async restore({ commit, dispatch }, id) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of(
        await ApiServices.restoreDeletedStaffForAdmin(id),
      ).pipe(map(response => response.data));
      response$.subscribe(staffs => {
        dispatch('isLoading', false, { root: true });
        dispatch('listWithTrash', `?page=1&size=10`);
        dispatch(
          'showSnackBar',
          {
            snackBar: true,
            message:
              staffs.data?.message ?? 'Staff member successfully restored',
            status: 'success',
          },
          { root: true },
        );
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while restoring staff member',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isLoading', false, { root: true });
    }
  },
  async transactions({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const { id, query } = payload;
      const response$ = of(
        await ApiServices.listTransactionsByStaff(id, query),
      ).pipe(map(response => response.data));
      response$.subscribe(transactions => {
        const {
          transactionList,
          totalTransactions,
          totalAmountByDate,
        } = transactions.data;
        const { docs, totalDocs, limit, page } = transactionList;
        commit('UPDATE_TRANSACTION_LIST', docs);
        commit('UPDATE_PAGINATION', { total: totalDocs, size: limit, page });
        commit('UPDATE_DETAILS', { ...totalTransactions, totalAmountByDate });
        dispatch('isPageLoading', false, { root: true });
      });
    } catch (e) {
      dispatch(
        'showSnackBar',
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            'An error occurred while restoring staff member',
          status: 'red',
        },
        { root: true },
      );
      dispatch('isPageLoading', false, { root: true });
    }
  },
};

const getters = {
  getAllStaffs: state => state.list,
  getAllTransactions: state => state.transactionList,
  getPagination: state => state.paginate,
  getStaffDetails: state => state.details,
};
export const staffs = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
